import { ReactNode } from 'react'

import { Notifications } from '@mantine/notifications'

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Notifications position="top-right" />
      {children}
    </>
  )
}
