import { CSSVariablesResolver, createTheme } from '@mantine/core'

import './font-import.css'
import './global-styles.css'

// https://mantine.dev/theming/theme-object/
export const theme = createTheme({
  focusRing: 'auto',
  scale: 1,
  headings: {
    fontFamily: "'Cera PRO', Inter, Avenir, Helvetica, Arial, sans-serif",
  },
  other: {
    colorBody: 'red',
  },
  spacing: {
    xl: 'max(var(--mantine-spacing-lg), 5vw)', // custom override.
  },
})

export const resolver: CSSVariablesResolver = (theme) => {
  // console.log(theme)
  return {
    variables: {
      '--mantine-hero-height': theme.other.heroHeight,
    },
    light: {
      '--mantine-color-body': theme.other.colorBody,
    },
    dark: {
      '--mantine-color-body': theme.other.colorBody,
    },
  }
}
